import React from 'react'
import siteConfig from 'config/site'
import Layout from 'layouts/default'
import Wrap from 'components/wrap'
import PaymentsForm from 'components/forms/payments-form'
import Countdown from 'components/countdown'
import Title from 'components/title'
import MarbleHeader from 'components/marble-header'

const PaymentsPage = () => (
  <Layout path="/payments">
    <MarbleHeader>
      <h1>One-Time Payments</h1>
    </MarbleHeader>
    {process.env.GATSBY_EVILNEON_CART_ENABLED === 'true' ? (
      <Wrap>
        <PaymentsForm />
      </Wrap>
    ) : (
      <Countdown date={siteConfig.launchDate}>
        <Title is="h2">{siteConfig.launchTitle}</Title>
      </Countdown>
    )}
  </Layout>
)

export default PaymentsPage
