import React from 'react'
import CountdownNow from 'react-countdown-now'
import styled from 'styled-components'
import { rem, flex } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import Wrap from 'components/wrap'
import Card from 'components/card'

const { colors, fonts } = theme
const { black, white, gray, vapor, pearl, pink, blue, yellow } = colors

const CountdownWrap = styled.div`
  ${flex('row', 'center', 'center')}
  background:
    linear-gradient(217deg, ${pink}, rgba(250, 79, 182, 0) 55%),
    linear-gradient(127deg, ${blue}, rgba(42, 221, 253, 0) 70%),
    linear-gradient(336deg, ${yellow}, rgba(254, 241, 94, 0) 150%);
  flex-wrap: wrap;

  ${media.medium`
    > div {
      max-width: ${rem(576)};
    }
  `}

  h1,
  h2,
  h3 {
    color: ${black};
    font-size: ${rem(24)};

    ${media.small`
      font-size: ${rem(28)};

      br {
        display: none;
      }
    `}
  }

  time {
    ${flex('row', 'center', 'center')}
    border-radius: ${rem(4)};
    padding: ${rem(16)};
  }

  ${Card} {
    box-shadow: none;
    font-size: ${rem(18)};
    font-weight: 600;
    min-width: ${rem(52)};
    overflow: hidden;
    position: relative;
    padding: 0 0 ${rem(8)};
    text-align: center;

    ${media.xsmall`
      font-size: ${rem(22)};
    `}

    :before {
      background: ${vapor};
      border-bottom: ${rem(1)} solid ${pearl};
      border-radius: ${rem(2)} ${rem(2)} 0 0;
      color: ${gray};
      content: attr(aria-label);
      display: block;
      font-family: ${fonts.primary};
      font-size: ${rem(14)};
      font-weight: 400;
      margin: 0 0 ${rem(12)};
      max-width: 100%;
      padding: ${rem(2)};
      text-transform: uppercase;
      width: 100%;
    }
  
    + ${Card} {
      margin-left: ${rem(16)};

      :after {
        bottom: ${rem(11)};
        color: ${white};
        content: ':';
        font-size: ${rem(20)};
        position: absolute;
        right: 100%;
        text-align: center;
        width: ${rem(16)};
      }
    }
  }
`

export default class Countdown extends React.Component {
  countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) return ''
    return (
      <time>
        <Card aria-label="Days">{days}</Card>
        <Card aria-label="Hours">{hours}</Card>
        <Card aria-label="Mins">{minutes}</Card>
        <Card aria-label="Secs">{seconds}</Card>
      </time>
    )
  }

  render() {
    const { date, children } = this.props

    if (!date || date <= new Date().getTime()) return <div />
    return (
      <CountdownWrap>
        <Wrap>
          {children}
          <CountdownNow date={date} renderer={this.countdownRenderer} />
        </Wrap>
      </CountdownWrap>
    )
  }
}
