import React from 'react'
import styled from 'styled-components'
import { rem } from 'styled-tidy'
import Wrap from 'components/wrap'
import theme from 'lib/styles/theme'
import marbleHeaderBackground from 'images/marble-header-backgrond.jpg'

const { colors, fonts } = theme
const { white } = colors

const MarblHeaderWrap = styled.div`
  background:
    url('${marbleHeaderBackground}') 50% 50% no-repeat;
  background-size: cover;
  color: ${white};
  padding: ${rem(48)} 0;

  h1,
  h2,
  h3 {
    font-family: ${fonts.primary};
    font-weight: 200;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
`

export const MobileHeader = ({ children, ...rest }) => (
  <MarblHeaderWrap {...rest}>
    <Wrap>{children}</Wrap>
  </MarblHeaderWrap>
)

export default MobileHeader
